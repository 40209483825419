import React, { useState, useEffect, createContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import clienteAxios from '../../config/axios';
import axiosAuth from '../../config/axiosAuth';
import {EN, ES} from '../../config/Language/Lenguage';


const LangContext = createContext();

const LangProvider = ({children}) => {

const [lang, setLang] = useState({
    data:EN,
    status:'EN',
});
const ActiveEN = () => {
    setLang({
        data:EN,
        status:'EN',
    })
}
const ActiveES = () => {   
    setLang({
        data:ES,
        status:'ES',
    })
} 
const [unsubwin, setWinSub] = useState(false);
const ToggleUnSub = () =>{
    setWinSub(!unsubwin)
}
// console.log(unsubwin) 




    return(
        <LangContext.Provider
            value={{
                lang,
                ActiveEN,
                ActiveES,
                ToggleUnSub,
                unsubwin
               
            }}
        >
            {children}
        </LangContext.Provider>
    )

}

export {
    
    LangProvider

}

export default LangContext

