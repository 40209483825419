import React, { useState } from 'react';
import useLang from "../../hooks/useLang";
import logoImage from "../../assets/img/logo/StripeL.svg";

const WinCancel = () => {
    const { unsubwin, ToggleUnSub } = useLang();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setLoading(true);

        try {
            const response = await fetch('https://api.picoaipicks.com/services/cancel-subscription.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const errorData = await response.json(); // Procesar el cuerpo del error
                throw new Error(errorData.message || 'Error desconocido');
            }

            const data = await response.json();
            setMessage(data.message);
        } catch (error) {
            setMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {unsubwin === true && (
                <section className="Win-Cancel">
                    <section className="Win-Cancel-for">
                        <img className="logostripe" src={logoImage} alt="" />
                        <h2 className="text-center text-disc">
                            Escriba su correo electrónico para cancelar su suscripción por la pasarela de pago Stripe
                        </h2>
                        <p className="text-center text-disc text-disc-font">
                            Escriba el correo electrónico donde tiene su suscripción activa.
                        </p>
                        <form className="formium" onSubmit={handleSubmit}>
                            <input
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <section className="cancelar-cancel" onClick={ToggleUnSub}>
                                Cerrar ventana
                            </section>
                            <button type="submit" className="btn btn-two" disabled={loading}>
                                {loading ? 'Loading...' : 'Send'}
                            </button>
                        </form>
                        {message && <p className="response-message">{message}</p>}
                    </section>
                </section>
            )}
        </>
    );
};

export default WinCancel;
